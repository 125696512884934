import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AddAnalyzes from '@/components/analyzes/AddAnalyzes.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import AddComment from '@/components/modals/AddComment.vue';
let AnalyzePage = class AnalyzePage extends Vue {
    constructor() {
        super(...arguments);
        this.addCommentModalState = false;
    }
    mounted() {
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, (id) => {
            this.addCommentModalState = !this.addCommentModalState;
            this.$store.commit('analyzes/setPropertyInStore', { name: 'commentAnalyzesId', value: id });
        });
    }
    addCommentClose(value) {
        this.addCommentModalState = value;
    }
    destroy() {
        bus.$off(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT);
    }
};
AnalyzePage = __decorate([
    Component({
        components: {
            AddAnalyzes,
            AddComment,
        },
    })
], AnalyzePage);
export default AnalyzePage;
